<template>
    <div class="row">
        <div class="col-12">
            <div class="card d-block w-100 border-0 shadow-xss rounded-lg overflow-hidden mb-4">
                <ul class="nav nav-tabs xs-p-4 d-flex align-items-center justify-content-around product-info-tab border-bottom-0">
                    <li class="list-inline-item" :class="{'active' : $route.name == 'UsersAdmin'}">
                        <router-link :to="{name: 'UsersAdmin'}" class="fw-700 pb-sm-4 pt-sm-4 xs-mb-2 font-xssss text-grey-500 ls-3 d-inline-block text-uppercase" :class="{'active' : $route.name == 'UsersAdmin'}">Internal</router-link>
                    </li>
                    <li class="list-inline-item" :class="{'active' : $route.name == 'UsersStudentAdmin'}">
                        <router-link :to="{name: 'UsersStudentAdmin'}" class="fw-700 pb-sm-4 pt-sm-4 xs-mb-2 font-xssss text-grey-500 ls-3 d-inline-block text-uppercase" :class="{'active' : $route.name == 'UsersStudentAdmin'}">Student</router-link>
                    </li>
                </ul>
            </div>
        </div>
        <div class="col-12">
            <div class="card d-block w-100 border-0 shadow-xss rounded-lg overflow-hidden p-4">
                <div class="row justify-content-center">
                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                        <router-link to="/admin/users/create" class="btn btn-current float-right mt-3"><i class="fas fa-plus"></i> Create New</router-link>
                        <h1 class="mt-4 font-weight-bolder"><i class="fas fa-users mr-3"></i>Students</h1>
                        <hr>
                        <div class="row justify-content-center d-flex" v-if="dataExist">
                            <div  class="col-xl-12 col-lg-12">
                                <div class="d-flex mx-1">
                                    <input type="text" class="form-control" placeholder="email" v-model="tableFilter">
                                    <button class="ml-3 btn btn-lg btn-current" @click="findStudent()">Search</button>
                                </div>
                            </div>
                            <div class="col-xl-12 col-lg-12 text-center py-3">
                                <div class="table-responsive mw-100 px-1">
                                    <datatable ref="dataTable" :class="'table table-hover table-bordered'" :columns="columns" :data="users" :perPage="10">
                                        <template name="default" slot-scope="{ row, index }">
                                            <tr>
                                                <td>{{ index+(page*queryLimit)-9 }}</td>
                                                <td>{{ row.name }}</td>
                                                <td>{{ row.username }}</td>
                                                <td>{{ row.email }}</td>
                                                <td>
                                                    <div v-if="!row.email_verified_at"><i class="feather-x-circle mr-1 text-danger"></i><span>Unverified</span></div>
                                                    <div v-else><i class="feather-check-circle mr-1 text-success"></i><span>Verified</span></div>
                                                </td>
                                                <td>
                                                    <div v-if="row.id !== user.id && row.role_id !== 1">
                                                        <router-link :to="'/profile/'+row.username"><i class="ti-info font-xs text-grey-500 mr-2"></i></router-link>
                                                        <button v-if="!row.email_verified_at" class="btn btn-sm btn-danger cursor-pointer" @click="forceVerify(row.id)">Force Verify</button>
                                                    </div>
                                                </td>
                                            </tr>
                                        </template>
                                        <template name="no-result">
                                            Nothing to see here
                                        </template>
                                    </datatable>
                                </div>
                                <div class="d-flex justify-content-end">
                                    <div class="icon-button py-2 mx-2" v-if="page > 1" @click="handleChangePage(-1)">
                                        <i class="feather-chevron-left"></i>
                                    </div>
                                    <div class="icon-button py-2 mx-2" v-if="users.length >= queryLimit" @click="handleChangePage(1)">
                                        <i class="feather-chevron-right"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 text-center py-3">
                                <div class="my-3" v-show="loadData">
                                    <div class="spinner-grow text-warning mx-3" role="status">
                                        <span class="sr-only">Loading...</span>
                                    </div>
                                    <div class="spinner-grow text-warning mx-3" role="status">
                                        <span class="sr-only">Loading...</span>
                                    </div>
                                    <div class="spinner-grow text-warning mx-3" role="status">
                                        <span class="sr-only">Loading...</span>
                                    </div>
                                    <div class="spinner-grow text-warning mx-3" role="status">
                                        <span class="sr-only">Loading...</span>
                                    </div>
                                    <div class="spinner-grow text-warning mx-3" role="status">
                                        <span class="sr-only">Loading...</span>
                                    </div>
                                    <div class="spinner-grow text-warning mx-3" role="status">
                                        <span class="sr-only">Loading...</span>
                                    </div>
        
                                </div>
                                <img :src="'/images/empty.png'" v-show="dataNotExist" alt="" width="300">
                                <h1 class="text-muted" v-show="dataNotExist">Data don't exist</h1>
                            </div>
                        </div>
                    </div>
                </div> 
            </div>
        </div>
    </div>
</template>

<script>
    import axios from 'axios'

    export default {
        name: "Users",
        data(){
            return {
                users: [],
                user: {},
                dataExist: false,
                dataNotExist: false,
                loadData: true,
                interval: null,
                tableFilter: '',
                columns: [
                    {label: 'No', field: 'id', headerClass: 'border-0 bg-current text-white p-3', class: 'product-thumbnail text-center', filterable: false, sortable: false},
                    {label: 'Name', field: 'name', headerClass: 'border-0 bg-current text-white p-3', class: 'product-thumbnail text-center wide-column'},
                    {label: 'Username', field: 'username', headerClass: 'border-0 bg-current text-white p-3', class: 'product-p text-center'},
                    {label: 'Email', field: 'email', headerClass: 'border-0 bg-current text-white p-3', class: 'product-price-wrapper text-center'},
                    {label: 'User Status', field: 'status', headerClass: 'border-0 bg-current text-white p-3', class: 'product-price-wrapper text-center'},
                    {label: 'Option', headerClass: 'border-0 bg-current text-white p-3', class: 'product-remove text-center', filterable: false, sortable: false},
                ],
                rows: [
                    this.users
                ],
                page: 1,
                queryLimit: 10,
                queryOffset: 0
            }
        },
        mounted() {
            this.getUsers()
            
        },
        beforeDestroy() {
            if(this.interval != null) {
                clearInterval(this.interval)
            }
        },
        methods: {
            async getUsers() {
                this.loadData = true
                await axios.get(`${process.env.VUE_APP_URL_API}/admin/user/v2?limit=${this.queryLimit}&offset=${this.queryOffset}`, {
                    headers: {
                        Authorization: `Bearer ${localStorage.token}`
                    }
                }).then(res => {
                    console.log("DATA USER", res.data)
                    this.loadData = false
                    if (res.data.length == 0) {
                        this.dataNotExist = true
                    } else {
                        this.dataExist = true
                        this.users = res.data
                        setTimeout(() => {
                            var table = this.$refs['dataTable']
                            let lastPage = localStorage.getItem('page-students')
                            if(table && lastPage) {
                                table.page = parseInt(lastPage)
                            }
                            this.interval = setInterval(() => {
                                localStorage.setItem('page-students', parseInt(table.page))
                            }, 1000)
                        }, 500)
                    }
                }).catch((err) => console.error(err))
            },
            handleChangePage(pageSum){
                this.page = this.page + pageSum
                this.queryOffset = this.queryLimit * this.page
                this.getUsers()
            },
            async findStudent(){
                this.isLoad = true
                if(this.tableFilter){
                    await axios.get(`${process.env.VUE_APP_URL_API}/admin/user/v2/search?email=${this.tableFilter}`, {
                        headers: {
                            Authorization: `Bearer ${localStorage.token}`
                        }
                    }).then(res => {
                        this.users = [res.data.data]
                    }).catch(err => {
                        console.error(err)
                        this.users = []
                    })
                }else {
                    await this.getUsers()
                }
                this.isLoad = false
            },
            async forceVerify(userId){
                console.log(userId)
                const payload = {
                    id: userId
                }
                axios.patch(`${process.env.VUE_APP_URL_API}/admin/user/v2/verify`, payload, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${localStorage.token}`
                    },
                }).then(res => {
                    console.log('success', res)
                    this.$swal({
                            toast: true,
                            title: 'Program',
                            text: 'Success Force Verify User',
                            icon: 'success',
                            position: 'top-end',
                            showConfirmButton: false,
                            timer: 2000,
                            timerProgressBar: true,
                        })
                    this.getUsers()
                }).catch(err => {
                    console.log(err)
                })
            }
        }
    }
</script>
